import { DateTime } from "luxon"
import { dateTimeFormat } from "./globals"
import dayjs from "dayjs"

/**
 * currentDateTimeFormatted returns formatted date time of current time but only 15, 30, 45, 00 mins are possible
 * @return {string} 05-10-2011 15:00
 */
export const currentDateTimeFormatted = () => {
  let d = new Date()
  let year = d.getFullYear()
  let month = ("0" + (d.getMonth() + 1)).slice(-2)
  let day = ("0" + d.getDate()).slice(-2)
  let hours = d.getHours()
  let minutes = "00"
  switch (true) {
    case d.getMinutes() < 15:
      minutes = "15"
      break
    case d.getMinutes() < 30:
      minutes = "30"
      break
    case d.getMinutes() < 45:
      minutes = "45"
      break
    case d.getMinutes() <= 59:
      minutes = "00"
      hours += 1
      break
  }
  hours = ("0" + hours).slice(-2)
  return day + "-" + month + "-" + year + " " + hours + ":" + minutes
}

/**
 * formatDateFromISO returns formatted date time value
 * @param  {string} value ISO
 * @param  {string} format "dd/LL/yyyy HH:mm:ss"
 * @return {string}
 */
export const formatDateFromISO = (value, format = dateTimeFormat.date) => {
  // most used formats
  // "dd/LL/yyyy HH:mm:ss" - 23/12/2022 18:45:50
  // "dd/LL/yyyy HH:mm" - 23/12/2022 18:45
  // "dd/LL/yyyy" - 23/12/2022
  // "HH:mm" - 18:45
  const parsed = DateTime.fromISO(value)

  return parsed.invalid ? "Invalid DateTime" : parsed.toFormat(format)
}

/**
 * formatDateFromISO returns formatted date time value
 * @param  {string} value ISO
 * @return {Date}
 */
export const formatISOToJSDate = (iso) => {
  return dayjs(iso).toDate()
}

/**
 * timeDiffForHumans formats a ISO value into a human-readable format
 *
 * @param {string} value ISO Date
 * @returns {string} Invalid DateTime | 1 min ago | 2 days ago
 */
export const timeDiffForHumans = value => {
  const parsed = DateTime.fromISO(value)

  return parsed.invalid ? "Invalid DateTime" : parsed.toRelative()
}
